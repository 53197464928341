import logo from './images/logo.svg';
import './App.scss';
import Header from './components/header/Header';
import Home from './pages/Home';
import { ReactNotifications } from 'react-notifications-component'

function App() {
  return (
    <>
      <Header />
      <Home />
      <ReactNotifications />
    </>
  );
}

export default App;
