import * as yup from 'yup'

export const SupportValidationSchema = yup.object().shape({
    fullName: yup.string().required('Full Name is required'),
    email: yup.string().email('Enter Valid Email').required('Email is required'),
    phoneNumber: yup.string().required('Phone Number is required'),
    timeZone: yup.string().required('Timezone is required'),
    issue: yup.string().required('Issue is required'),
    description: yup.string().required('Description is required')
})

export const MdxValidationSchema = yup.object().shape({
    fullName: yup.string().required('Full Name is required'),
    email: yup.string().email('Enter Valid Email').required('Email is required'),
    issue: yup.string().required('Issue is required'),
    description: yup.string().required('Description is required')
})