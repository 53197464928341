import buttonSubmit from '../../images/button-submit.png'
import buttonCancel from '../../images/button-cancel.png'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import ErrorMessage from '../../common/error-msg/ErrorMessage'
import { MdxValidationSchema } from '../../common/validation/validations'
import { useEffect, useRef } from 'react'
import { getCookie, setCookie } from '../../utils/cookie-utils'
import { cookieNames } from '../../common/constants/base_constants'
import apiUtil from '../../utils/api-utils'
import showAlert from '../../common/alert/showAlert'
import { alertMessage } from '../../common/constants/message_constants'
import Captcha from '../../common/captcha/Captcha'

function MdxSupportForm(prop) {

  const methods = useForm({
    resolver: yupResolver(MdxValidationSchema)
  })

  const { register, handleSubmit, reset, formState: { errors } } = methods
  const recaptchaRef = useRef()

  useEffect(() => {
    resetForm();
  }, [])

  function resetForm() {
    const formData = getCookie(cookieNames.supportFormCookie);
    if (formData) {
      const formDataObj = JSON.parse(formData);
      reset({
        fullName: formDataObj.fullName,
        email: formDataObj.email,
      })
    }
  }

  function setCookieObject(dataObj) {
    setCookie(cookieNames.supportFormCookie, dataObj);
  }

  function callApi(dataObj) {
    const apiParams = {
      url: "SendEmailTrigger",
      method: "POST",
      data: dataObj,
      headerInfo: [
        {
          key: "x-helpdesk-request-type",
          value: "MDX"
        }
      ]
    }
    apiUtil(apiParams).then(response => {
      if (response.data) {
        showAlert({ type: "success", msg: alertMessage.mdxSupportSuccess, duration: 6000 })
        setCookieObject(dataObj)
      } else {
        showAlert({ type: "error", msg: alertMessage.mdxSupportError })
      }
    })
  }

  const onSubmitForm = (data) => {
    recaptchaRef.current.executeAsync().then(captchaToken => {
      const otherData = { userLogin: data.email, "g-recaptcha-response": captchaToken }
      const dataObj = { ...data, ...otherData }
      prop.handleClick(false)
      callApi(dataObj)
    })
  }

  return (
    <div className="support-form">
      <form name="form" onSubmit={handleSubmit(onSubmitForm)}>
        <div className="form-group">
          <label htmlFor="fullName">Full Name*</label>
          <input type="text" placeholder="John Doe"
            className={`form-control ${errors.fullName?.message && 'error'}`} {...register('fullName')} />
          <ErrorMessage errorMsg={errors.fullName?.message} />
        </div>

        <div className="form-group">
          <label htmlFor="email">Email*</label>
          <input type="email" placeholder="jdoe@email.com"
            className={`form-control ${errors.email?.message && 'error'}`} {...register('email')} />
          <ErrorMessage errorMsg={errors.email?.message} />
        </div>
        <div className="form-group">
          <label htmlFor="issue">Issue*</label>
          <input type="text" placeholder="Issue Summary"
            className={`form-control ${errors.issue?.message && 'error'}`} {...register('issue')} />
          <ErrorMessage errorMsg={errors.issue?.message} />
        </div>
        <div className="form-group">
          <label htmlFor="desc">Description*</label>
          <textarea rows="7" name="desc"
            className={`form-control ${errors.description?.message && 'error'}`} {...register('description')}></textarea>
          <ErrorMessage errorMsg={errors.description?.message} />
        </div>
        <div className="form-group captcha">
          <Captcha reference={recaptchaRef} />
        </div>
        <div className="row button-content">
          <div className="col align-self-end required">*Required</div>
          <div className="col text-right pr-0">
            <a onClick={() => prop.handleClick(false)}>
              <img className="create-btn" src={buttonCancel} alt="" />
            </a>
          </div>
          <div className="col align-self-end pr-0">
            <a href=''>
              <input className="create-btn" type="image" src={buttonSubmit} alt="Submit" />
            </a>
          </div>
        </div>
      </form>
    </div>
  )
}
export default MdxSupportForm