import ReCAPTCHA from "react-google-recaptcha";

export const Captcha = (props) => {
  
  const recaptchaSiteKey= process.env.REACT_APP_RECAPTCHA_SITE_KEY
  
  return (
    <ReCAPTCHA
      sitekey={recaptchaSiteKey}
      ref={props.reference}
      size="invisible"
    />
  )
}
export default Captcha