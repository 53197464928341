import buttonSubmit from '../../images/button-submit.png'
import buttonCancel from '../../images/button-cancel.png'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import ErrorMessage from '../../common/error-msg/ErrorMessage'
import { SupportValidationSchema } from '../../common/validation/validations'
import { timeZoneOptions, contactTimeOptions, cookieNames } from '../../common/constants/base_constants'

import { useEffect, useRef } from 'react'
import showAlert from '../../common/alert/showAlert'
import apiUtil from '../../utils/api-utils'
import { alertMessage } from '../../common/constants/message_constants'
import { getCookie, setCookie } from '../../utils/cookie-utils'
import Captcha from '../../common/captcha/Captcha'

function ItSupportForm(prop) {

  const methods = useForm({
    resolver: yupResolver(SupportValidationSchema)
  })

  const { register, handleSubmit, reset, formState: { errors } } = methods
  const recaptchaRef = useRef()

  useEffect(() => {
    resetForm();
  }, []);

  function resetForm() {
    const formData = getCookie(cookieNames.supportFormCookie);
    if (formData) {
      const formDataObj = JSON.parse(formData);
      reset({
        fullName: formDataObj.fullName,
        email: formDataObj.email,
        phoneNumber: formDataObj.phoneNumber,
        contactTime: formDataObj.contactTime,
        timeZone: formDataObj.timeZone
      })
    }
  }

  const onSubmitForm = (data) => {
    recaptchaRef.current.executeAsync().then(captchaToken => {
      const otherData = { userLogin: data.email, "g-recaptcha-response": captchaToken }
      let dataObj = { ...data, ...otherData }
      prop.handleClick(false)

      if (data.uploadFiles) {
        let fileBase64 = [];
        const filePromises = toBase64(data.uploadFiles, fileBase64);
        Promise.all(filePromises).then(() => {
          dataObj.attachments = fileBase64;
          callApi(dataObj);
        })
          .catch((error) => {
            console.log(error);
            console.log("something wrong happened");
          })
      } else {
        callApi(dataObj)
      }
    })
  }

  function setCookieObject(dataObj) {
    delete dataObj['issue'];
    delete dataObj['description'];
    delete dataObj['attachments'];
    setCookie(cookieNames.supportFormCookie, dataObj);
  }

  function callApi(dataObj) {
    delete dataObj['uploadFiles'];
    const apiParams = {
      url: "SendEmailTrigger",
      method: "POST",
      data: dataObj,
      headerInfo: [
        {
          key: "x-helpdesk-request-type",
          value: "ITSUPPORT"
        }
      ]
    }
    apiUtil(apiParams).then(response => {
      if (response.data) {
        showAlert({ type: "success", msg: alertMessage.itSupportSuccess, duration: 6000 })
        setCookieObject(dataObj)
      } else {
        showAlert({ type: "error", msg: alertMessage.itSupportError })
      }
    })
  }

  function toBase64(files, fileBase64) {
    return Object.entries(files).map((item) => {
      const file = item[1];
      return new Promise((resolve, reject) => {
        //const [file] = item;
        let uploadedFile = {}

        var reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = function () {
          uploadedFile.content = reader.result;
          uploadedFile.fileName = file.name || 'filename.txt';
          uploadedFile.fileType = file.type || '.txt';

          fileBase64.push(uploadedFile)
          resolve()
        }
        reader.onerror = function () {
          console.log("can't read the file")
          reject()
        }
      })
    })
  }

  return (
    <div className="support-form">
      <form name="form" onSubmit={handleSubmit(onSubmitForm)}>
        <div className="form-group">
          <label htmlFor="fullName">Full Name*</label>
          <input type="text" placeholder="John Doe"
            className={`form-control ${errors.fullName?.message && 'error'}`} {...register('fullName')} />
          <ErrorMessage errorMsg={errors.fullName?.message} />
        </div>

        <div className="form-group">
          <label htmlFor="email">Email*</label>
          <input type="email" placeholder="jdoe@email.com"
            className={`form-control ${errors.email?.message && 'error'}`} {...register('email')} />
          <ErrorMessage errorMsg={errors.email?.message} />
        </div>
        <div className="form-group">
          <label htmlFor="phone">Phone Number*</label>
          <input type="text" placeholder="123-456-7890"
            className={`form-control ${errors.phoneNumber?.message && 'error'}`} {...register('phoneNumber')} />
          <ErrorMessage errorMsg={errors.phoneNumber?.message} />
        </div>
        <div className='row'>
          <div className="form-group col-md-6">
            <label htmlFor="contactTime">Best Contact Time*</label>
            <select name="contactTime" className="form-select" {...register('contactTime')}>
              {contactTimeOptions.map(option => {
                return (<option key={option.key} value={option.key}>{option.value}</option>)
              })}
            </select>
          </div>
          <div className="form-group col-md-6">
            <label htmlFor="timeZone">Time Zone*</label>
            <select name="timeZone"
              className={`form-select ${errors.timeZone?.message && 'error'}`} {...register('timeZone')}>
              {timeZoneOptions.map(option => {
                return (<option key={option.key} value={option.key}>{option.value}</option>)
              })}
            </select>
            <ErrorMessage errorMsg={errors.timeZone?.message} />
          </div>
        </div>
        <div className="form-group">
          <label htmlFor="issue">Issue*</label>
          <input type="text" placeholder="Issue Summary"
            className={`form-control ${errors.issue?.message && 'error'}`} {...register('issue')} />
          <ErrorMessage errorMsg={errors.issue?.message} />
        </div>
        <div className="form-group">
          <label htmlFor="desc">Description*</label>
          <textarea rows="7" name="desc"
            className={`form-control ${errors.description?.message && 'error'}`} {...register('description')}></textarea>
          <ErrorMessage errorMsg={errors.description?.message} />
        </div>
        <div className="form-group files">
          <label>Attachment(s) - PDF/Image/Word/Txt Only</label>
          <input type="file" multiple accept="image/*,.doc, .docx,.txt,.pdf" {...register('uploadFiles')} />
        </div>
        <div className="form-group captcha">
          <Captcha reference={recaptchaRef} />
        </div>
        <div className="row button-content">
          <div className="col align-self-start required">*Required</div>
          <div className="col text-right pr-0">
            <a onClick={() => prop.handleClick(false)}>
              <img className="create-btn" src={buttonCancel} alt="" />
            </a>
          </div>
          <div className="col align-self-end pr-0">
            <a href=''>
              <input className="create-btn" type="image" src={buttonSubmit} alt="Submit" />
            </a>
          </div>
        </div>
      </form>
    </div>
  )
}
export default ItSupportForm