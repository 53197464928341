import { useState } from 'react'
import settingIcon from '../../images/settings-icon.png'
import button from '../../images/button.png'
import ItSupportForm from './ItSupportForm'

function ItSupport() {

  const [showForm, setShowForm] = useState(false)

  const handleClick = (val) => {
    setShowForm(val)
  }

  return (
    <div className="support-box it">
      <a onClick={() => handleClick(true)}>
        <div className="click-area">
          <h2>
            <img src={settingIcon} alt="" /> Desktop Support
          </h2>
          <p>Request support for software installed on your computer and products used by you to get your work done.
          </p>
          <ul className="list-items">
            <li><span>Office 365</span></li>
            <li><span>Outlook</span></li>
            <li><span>Laptop</span></li>
            <li><span>Adobe</span></li>
            <li><span>Distribution Lists</span></li>
            <li><span>Onboarding</span></li>
          </ul>
          <div>
            <table className="table table-bordered support-table">
              <thead>
                <tr>
                  <th scope="col">Trouble</th>
                  <th scope="col">Priority</th>
                  <th scope="col">Response</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Service available (all users and functions unavailable)</td>
                  <td>1</td>
                  <td>Within 15 minutes</td>
                </tr>
                <tr className="even">
                  <td>Significant degradation of service (large number of users or business
                    critical functions affected)</td>
                  <td>2</td>
                  <td>Within 30 minutes</td>
                </tr>
                <tr>
                  <td>Limited degradation of Service (limited number of users and functions
                    affected, business process can continue)</td>
                  <td>3</td>
                  <td>Within 1 hour</td>
                </tr>
                <tr className="even">
                  <td>Small service degradation (business process can continue, one user affected)</td>
                  <td>4</td>
                  <td>Within 2-4 hour</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="row">
            <div className="col support-hour">
              Desktop Support Hours: 24/7 x 365<br />
              Emergencies Contact: (888) 563-9132
            </div>
            {!showForm && <div className="col text-end" id="createSupportButton">
              <img className="create-btn" src={button} alt="" />
            </div>}
          </div>
        </div>
      </a>
      {showForm && <ItSupportForm handleClick={handleClick} />}
    </div>
  );
}
export default ItSupport;
