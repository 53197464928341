import { useState } from 'react'
import chartIcon from '../../images/chart-icon.png'
import button from '../../images/button.png';
import MdxSupportForm from './MdxSupportForm';
import mdxContact from '../../images/mdx-contact.png'

function MdxSupport() {

  const [showForm, setShowForm] = useState(false);

  const handleClick = (val) => {
    setShowForm(val);
  }

  return (
    <div className="support-box mdx">
      <a onClick={() => handleClick(true)} >
        <div className="click-area">
          <h2>
            <img src={chartIcon} alt="" /> MDX Support
          </h2>
          <p>Request support for Microsoft Dynamics (MDX).
          </p>
          <br /><br />
          <div className="row">
            <div className="col support-hour">
              MDX Support Hours: Monday - Friday 8am-5pm (MST)<br />
              Emergencies Contact: <img src={mdxContact} alt='' />
            </div>
            {!showForm && <div className="col col-lg-4 col-xs-12 pl-0 text-end responsive-button">
              <img className="create-btn" src={button} alt="" />
            </div>}
          </div>
        </div>
      </a>
      {showForm && <MdxSupportForm handleClick={handleClick} />}
    </div>
  );
}
export default MdxSupport;