import ItSupport from '../components/home/ItSupport'
import AppSupport from '../components/home/AppSupport'
import MdxSupport from '../components/home/MdxSupport'

function Home() {
  return (
    <>
      <section className="heading">
        <p className="sub-head">This is a portal for Digital Air Strike employees to submit tickets related to DAS products or software on their laptops</p>
      </section>
      <section className="content-area">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-xs-12">
              <ItSupport />
            </div>
            <div className="col-md-6 col-xs-12">
              <AppSupport />    
              <MdxSupport />      
            </div>
          </div>
        </div>
      </section></>
  );
}
export default Home
