export const contactTimeOptions = [
  { key: 'Anytime', value: 'Anytime' },
  { key: 'Morning', value: 'Morning' },
  { key: 'Afternoon', value: 'Afternoon' }
]

export const timeZoneOptions = [
  { key: '', value: 'Select' },
  { key: 'Eastern Daylight Time', value: 'Eastern Daylight Time' },
  { key: 'Central Daylight Time', value: 'Central Daylight Time' },
  { key: 'Mountain Daylight Time', value: 'Mountain Daylight Time' },
  { key: 'Mountain Standard Time', value: 'Mountain Standard Time' },
  { key: 'Pacific Daylight Time', value: 'Pacific Daylight Time' },
  { key: 'Alaska Daylight Time', value: 'Alaska Daylight Time' },
  { key: 'Hawaii-Aleutian Daylight Time', value: 'Hawaii-Aleutian Daylight Time' },
  { key: 'Hawaii Standard Time', value: 'Hawaii Standard Time' }
]

export const cookieNames = {
  supportFormCookie: 'SUPPORT_FORM_DATA'
}