import axios from 'axios'

const BASE_URL = process.env.REACT_APP_BASE_URL;

const apiUtil = (apiParams) => {
  const {
    url,
    method,
    data,
    headerInfo
  } = apiParams

  axios.defaults.headers.common['Content-Type'] = 'application/json'
  headerInfo?.forEach(headerType => {
    axios.defaults.headers.common[ headerType.key ] = headerType.value
  })
  const dataOrParams = ['GET', 'DELETE'].includes(method) ? 'params' : 'data'

  return axios
    .request({
      url: BASE_URL+url,
      method,
      [dataOrParams]: data
    })
    .then(response => {      
      return {
        data: response
      }
    })
    .catch(error => {
      return {
        error: error
      }
    })
}
export default apiUtil