import logo from '../../images/das-logo.png';

function Header() {
    return (
        <header className="header">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-4 col-12 logo">
              <img src={logo} />
            </div>
            <div className="col-md-4 col-12 text-center">
              <h1>Support Portal</h1>
            </div>
            <div className="col"></div>
          </div>
        </div>
      </header>
    );
  }  
  export default Header;