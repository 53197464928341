import { Store } from 'react-notifications-component'
import alertCheckmark from '../../images/checkmark-circle.svg'
import 'react-notifications-component/dist/theme.css'

function showAlert(props) {
  return (
    Store.addNotification({
      content: <AlertMessage type={props.type} msg={props.msg} />,
      container: "top-center",
      dismiss: {
        duration: props.duration || 12000
      }
    })
  )
}

function AlertMessage(props) {
  return (
    <div className={props.type === "success" ? "alert-msg alert-msg-success" : "alert-msg alert-msg-error"}>
      <p>
        {props.type === "success" && <img src={alertCheckmark} width="20" alt="checkmark" /> }
        {props.msg}
      </p>
      <button className='alert-btn' onClick={() => Store.removeAllNotifications()}>x</button>
    </div >
  )
}

export default showAlert