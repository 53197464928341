import cubeIcon from '../../images/cube-icon.png';
import button from '../../images/button.png';
import appContact from '../../images/app-contact.png'

function AppSupport() {
  return (
    <a href="https://digitalairstrike.atlassian.net/servicedesk/customer/portal/22" target="_blank">
      <div className="support-box product">
        <h2>
          <img src={cubeIcon} alt="" /> Application Support
        </h2>
        <p>Request support for products built by DAS that generate revenue for customers.</p>
        <ul className="list-items">
          <li><span>Response Logix</span></li>
          <li><span>Social Logix</span></li>
          <li><span>Media Logix</span></li>
          <li><span>Credit Logix</span></li>
          <li><span>Sales Boost</span></li>
          <li><span>LotVantage</span></li>
          <li><span>BestRide</span></li>
          <li><span>Response Path</span></li>
          <li><span>Inventory</span></li>
        </ul>
        <div className="row">
          <div className="col col-lg-8 col-xs-12 support-hour">
            Application Support Hours: 24/7 x 365<br />
            Emergencies Contact: <img src={appContact} alt='' />
          </div>
          <div className="col col-lg-4 col-xs-12 pl-0 text-end responsive-button">
            <img className="create-btn" src={button} alt="" />
          </div>
        </div>
      </div>
    </a>
  );
}
export default AppSupport;